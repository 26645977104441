import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import './estilos.css';
import React from 'react';

interface PageState extends SceneObjectState {
  isActive: boolean;
}

export class SensoresPage extends SceneObjectBase<PageState> {
  public static Component = SensoresPageRenderer;
}

function SensoresPageRenderer({ model }: SceneComponentProps<SensoresPage>) {
  const { isActive } = model.useState();

  return (
    <>
      <div>
        <div>{isActive}</div>
        <div className="center">
          <div>
            <button className="button"> Máquinas </button>
          </div>
          <div>
            <button className="button"> Sensor </button>
          </div>
          <div>
            <button className="button"> Criar Visualização </button>
          </div>
        </div>
      </div>
    </>
  );
}
