import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import './estilos.css';
import React from 'react';
import { useHistory } from "react-router-dom";
import { ROUTES } from '../../constants';
import { prefixRoute } from '../../utils/utils.routing';

interface PageState extends SceneObjectState {
	isActive: boolean;
}

export class AlertasPage extends SceneObjectBase<PageState> {
	public static Component = AlertasPageRenderer;
}

function AlertasPageRenderer({ model }: SceneComponentProps<AlertasPage>) {
	const { isActive } = model.useState();
	const history = useHistory();

	const redirectToPontosDeContacto = () => {
		history.push(prefixRoute(`${ROUTES.PontoContacto}`));
	}

	const redirectToCriarAlerta = () => {
		history.push(prefixRoute(`${ROUTES.CriarAlerta}`));
	}

	const redirectToListarAlertas = () => {
		history.push(prefixRoute(`${ROUTES.ListarAlertas}`));
	}

	return (
		<>
			<div>
				<div>{isActive}</div>
				<div className="center">
					<div>
						<button className="button" onClick={redirectToCriarAlerta} >Criar Alerta</button>
					</div>
					<div>
						<button className="button" onClick={redirectToListarAlertas} >Listar Alertas</button>
					</div>
					<div>
						<button className="button" onClick={redirectToPontosDeContacto} >Pontos de contacto</button>
					</div>
				</div>
			</div>
		</>
	);
}
