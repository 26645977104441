import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import '../estilos.css';
import React from 'react';

interface PageState extends SceneObjectState {
  isActive: boolean;
}

export class CriarVisualizacaoPage extends SceneObjectBase<PageState> {
  public static Component = CriarVisualizacaoPageRenderer;
}

function CriarVisualizacaoPageRenderer({ model }: SceneComponentProps<CriarVisualizacaoPage>) {
  const { isActive } = model.useState();

  return <div> {isActive} </div>;
}
