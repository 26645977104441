import {
  EmbeddedScene,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneGridItem,
  SceneGridLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
  VizPanel,
} from '@grafana/scenes';
import { RootDashboardJsonType } from '../../utils/DashboardTypes';
import { DataQuery, DataSourceRef } from '@grafana/data';

// Big function para renderizar o dashboard
export function DashboardRenderer(
  dashboardJson?: RootDashboardJsonType,
  variableSet?: SceneVariableSet,
  variableValue?: string,
  sensorIdsMap?: { [key: string]: string }
) {
  // Se não obter JSON render uma cena default
  if (!dashboardJson) {
    return new EmbeddedScene({
      $timeRange: new SceneTimeRange({ from: 'now-1h', to: 'now' }),
      $variables: variableSet,
      controls: [new VariableValueSelectors({})],
      body: new SceneGridLayout({
        children: [
          new SceneGridItem({
            x: 0,
            y: 0,
            width: 12,
            height: 10,
            body: new VizPanel({
              pluginId: 'timeseries',
              title: 'A carregar...',
              $data: new SceneQueryRunner({
                datasource: { type: '', uid: '' },
                queries: [],
              }),
            }),
          }),
        ],
      }),
    });
  } else {
    // Se o dashboardJson estiver carregado, chama a função DashboardJSON para renderizar os painéis
    return DashboardJSON(dashboardJson, variableSet, variableValue, sensorIdsMap);
  }
}

// Função que constrói a cena do dashboard com base no JSON, variáveis e valor selecionado
export const DashboardJSON = (
  dashboardJson: RootDashboardJsonType,
  variableSet?: SceneVariableSet,
  variableValue?: string,
  sensorIdsMap?: { [key: string]: string }
) => {
  /* const timeRange = new SceneTimeRange({
    from: dashboardJson.dashboard.time.from,
    to: dashboardJson.dashboard.time.to,
  }); */

  const panels = dashboardJson.dashboard.panels;

  // Mapear o nome do sensor de volta para o ID
  const sensorId = sensorIdsMap && variableValue ? sensorIdsMap[variableValue] : undefined;

  // Filtra os painéis com base no valor da variável selecionada
  const filteredPanels = panels.filter((panel) => {
    if (!sensorId) {
      return false; // Se não houver sensorId, não inclui o painel
    }
    return panel.title.startsWith(`${sensorId}-`); // Verifica se o título começa com o ID seguido de '-'
  });

  // Define o tamanho dos painéis e os parâmetros da grid
  const panelWidth = 12;
  const panelHeight = 8;
  const columns = 2;

  // Mapeia os painéis filtrados para SceneGridItems com posições calculadas
  const sceneGridItems = filteredPanels.map((panel, index) => {
    const column = index % columns;
    const row = Math.floor(index / columns);
    const x = column * panelWidth;
    const y = row * panelHeight;

    const dataProvider = new SceneQueryRunner({
      datasource: panel.datasource as DataSourceRef,
      queries: panel.targets.map((target) => {
        const query: DataQuery = {
          ...target,
        };
        return query;
      }),
    });

    // Verifica se o painel tem transformações
    let panelData: SceneQueryRunner | SceneDataTransformer = dataProvider;
    if (panel.transformations && panel.transformations.length > 0) {
      panelData = new SceneDataTransformer({
        $data: dataProvider,
        transformations: panel.transformations,
      });
    }

    // Extrair o param
    const originalTitle = panel.title;
    const titleParts = originalTitle.split('-');
    const panelTitle = titleParts.length > 1 ? titleParts.slice(1).join('-') : originalTitle;

    // Prepara o VizPanel com o título extraído
    const vizPanel = new VizPanel({
      pluginId: panel.type,
      title: panelTitle,
      description: panel.description,
      options: panel.options,
      pluginVersion: panel.pluginVersion,
      $data: panelData,
    });

    // Cria o SceneGridItem com as posições calculadas e o vizPanel
    return new SceneGridItem({
      x: x,
      y: y,
      width: panelWidth,
      height: panelHeight,
      body: vizPanel,
    });
  });

  return new EmbeddedScene({
    $timeRange: new SceneTimeRange({ from: 'now-3h', to: 'now' }),
    $variables: variableSet,
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({ intervals: ['5s', '1m', '1h'], isOnCanvas: true }),
    ],

    body: new SceneGridLayout({
      children: sceneGridItems,
    }),
  });
};

export default DashboardRenderer;
