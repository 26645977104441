import React from 'react';
import { AppRootProps } from '@grafana/data';
import { PluginPropsContext } from '../../utils/utils.plugin';
import { Routes } from '../Routes';

// export class App extends React.PureComponent<AppRootProps> {
//   render() {
//     return (
//       <PluginPropsContext.Provider value={this.props}>
//         <Routes />
//       </PluginPropsContext.Provider>
//     );
//   }
// }

export const App = (props: AppRootProps) => {
  React.useEffect(() => {
    if (window) {
      // const sidebar = window.document.querySelector(
      //   '[data-testid="data-testid navigation mega-menu"]'
      // ) as HTMLDivElement;
      // const toolbarHamburgerMenu = window.document.querySelector('[id="mega-menu-toggle"]') as HTMLDivElement;
      // const toolbarTitle = window.document.querySelector('[aria-label="Breadcrumbs"]') as HTMLDivElement;
      //sidebar.style.display = 'none';
      //toolbarTitle.innerHTML = 'on.Sense';
      //toolbarHamburgerMenu.style.display = 'none';
    }
  }, []);
  return (
    <PluginPropsContext.Provider value={props}>
      <Routes />
    </PluginPropsContext.Provider>
  );
};
