import { getDataSourceSrv } from '@grafana/runtime';
import { from, lastValueFrom, Observable } from 'rxjs';
import { DataQueryResponse, DataSourceApi, dateTime } from '@grafana/data';

interface Device {
	id: number;
	device_Name?: string;
	device_Identifier: string;
	device_Model: string;
	companyId?: number;
	parameters?: any;
}

const dataSourceSrv = getDataSourceSrv();
const infinityUid = "P9EF444E4C3795D6F"

const apiUrlBase = "https://iiotstonerestapi.azurewebsites.net/api"
const apiUrlDevices = apiUrlBase + "/Device/GetDevices"
const apiUrlDevice = apiUrlBase + "/Device"

const devicesTarget = {
	"columns": [],
	"datasource": {
		"type": "yesoreyeram-infinity-datasource",
		"uid": "P9EF444E4C3795D6F" // testar sem uid só com o type
	},
	"filters": [],
	"format": "table",
	"global_query_id": "",
	"refId": "A",
	"root_selector": "",
	"source": "url",
	"type": "json",
	"url": apiUrlDevices,
	"url_options": {
		"data": "",
		"method": "GET"
	}
}

const fetchDevices = async function (): Promise<Device[]> {
	const dataSource: DataSourceApi = await dataSourceSrv.get(infinityUid);

	const query: Observable<DataQueryResponse> = from(dataSource.query({
		requestId: "some",
		interval: "1s",
		intervalMs: 1000,
		range: {
			from: dateTime(),
			to: dateTime(),
			raw: {
				from: "",
				to: ""
			},
		},
		scopedVars: {},
		timezone: "",
		app: "",
		targets: [
			devicesTarget
		],
		startTime: 0
	}))

	return lastValueFrom(query).then((response: DataQueryResponse) => {
		return response.data[0].meta.custom.data
	})
}

const fetchDevice = async function (deviceId: string): Promise<Device> {
	const dataSource: DataSourceApi = await dataSourceSrv.get(infinityUid);

	const deviceTarget = {
		"columns": [],
		"datasource": {
			"type": "yesoreyeram-infinity-datasource",
			"uid": "P9EF444E4C3795D6F"
		},
		"filters": [],
		"format": "table",
		"global_query_id": "",
		"refId": "A",
		"root_selector": "",
		"source": "url",
		"type": "json",
		"url": apiUrlDevice + "/" + deviceId,
		"url_options": {
			"data": "",
			"method": "GET"
		}
	}

	const query: Observable<DataQueryResponse> = from(dataSource.query({
		requestId: "some",
		interval: "1s",
		intervalMs: 1000,
		range: {
			from: dateTime(),
			to: dateTime(),
			raw: {
				from: "",
				to: ""
			},
		},
		scopedVars: {},
		timezone: "",
		app: "",
		targets: [
			deviceTarget
		],
		startTime: 0
	}))

	return lastValueFrom(query).then((response: DataQueryResponse) => {
		if (response.data.length != 0) {
			return response.data[0].meta.custom.data
		}
	})
}

export { Device, fetchDevices, fetchDevice };
