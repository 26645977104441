import { EmbeddedScene, SceneApp, SceneAppPage, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { ROUTES } from '../../constants';
import { prefixRoute } from '../../utils/utils.routing';
import React, { useMemo } from 'react';
import { SensoresPage } from './CustomSceneObject';

export function myScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new SensoresPage({ isActive: true }),
        }),
      ],
    }),
  });
}

const getScene = () =>
  new SceneApp({
    pages: [
      new SceneAppPage({
        title: 'Pagina inicial Sensores',
        url: prefixRoute(`${ROUTES.HomeSensores}`),
        hideFromBreadcrumbs: false,
        getScene: () => {
          return myScene();
        },
      }),
    ],
  });

export const HomeSensores = () => {
  const scene = useMemo(() => getScene(), []);

  return <scene.Component model={scene} />;
};
