import { SceneApp, SceneAppPage, CustomVariable, SceneVariableSet } from '@grafana/scenes';
import { ROUTES } from '../../../constants';
import { prefixRoute } from '../../../utils/utils.routing';
import React, { useEffect, useMemo, useState } from 'react';
import { fetchDashboardJson } from './fetchDashboard';
import DashboardRenderer from 'pages/JsonToDash/DashboardRenderer';
import { RootDashboardJsonType } from 'utils/DashboardTypes';

export const Sensor = () => {
  const [dashboardJson, setDashboardJson] = useState<RootDashboardJsonType | undefined>();

  // Define o mapeamento de IDs para nomes
  const sensorNamesMap: { [key: string]: string } = {
    '8065': 'Genoa Spark EMS',
    '8066': 'Genoa Spark Water Counter',
    '8067': 'GenoaSpark AirQ',
    '8068': 'Sensorext',
  };

  // Cria o mapeamento reverso de nomes para IDs
  const sensorIdsMap: { [key: string]: string } = {};
  for (const id in sensorNamesMap) {
    const name = sensorNamesMap[id];
    sensorIdsMap[name] = id;
  }

  // Lista de nomes dos sensores sem 'Todos'
  const sensorNames = Object.values(sensorNamesMap);

  // Define o valor inicial como o primeiro nome da lista
  const [selectedValue, setSelectedValue] = useState<string>(sensorNames[0]);

  // useEffect JSON dashboard
  useEffect(() => {
    const loadDashboard = async () => {
      try {
        const dashboardJson = await fetchDashboardJson();
        setDashboardJson(dashboardJson);
        console.log('Dashboard:', dashboardJson);
      } catch (error) {
        console.error('Erro ao carregar o dashboard:', error);
      }
    };

    loadDashboard();
  }, []);

  const scene = useMemo(() => {
    const sensorIdVar = new CustomVariable({
      name: 'Sensor',
      query: sensorNames.join(','),
    });

    sensorIdVar.setState({ value: selectedValue });

    // Subscreve mudanças de estado da variável para atualizar o selectedValue
    const subscription = sensorIdVar.subscribeToState((newState, prevState) => {
      const value = sensorIdVar.getValue() as string;
      setSelectedValue(value);
    });

    const variableSet = new SceneVariableSet({ variables: [sensorIdVar] });

    const getScene = (dashboardJson?: RootDashboardJsonType) =>
      new SceneApp({
        pages: [
          new SceneAppPage({
            title: 'Pagina Sensor',
            url: prefixRoute(ROUTES.Sensor),
            hideFromBreadcrumbs: false,
            getScene: () => {
              return DashboardRenderer(dashboardJson, variableSet, selectedValue, sensorIdsMap);
            },
          }),
        ],
      });

    const sceneApp = getScene(dashboardJson);

    // Retorna a cena e uma função de limpeza para cancelar a subscrição quando a cena for re-renderizada
    return {
      scene: sceneApp,
      cleanup: () => {
        subscription.unsubscribe();
      },
    };
  }, [dashboardJson, selectedValue]);

  // useEffect para limpar a subscrição quando o componente for desmontado ou a cena mudar
  useEffect(() => {
    return () => {
      if (scene && scene.cleanup) {
        scene.cleanup();
      }
    };
  }, [scene]);

  return <>{scene && <scene.scene.Component key={selectedValue} model={scene.scene} />}</>;
};
