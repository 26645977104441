import { getBackendSrv } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';

const alertsUrl = "/api/v1/provisioning/alert-rules";

interface ProvisionedAlertRule {
	annotations?: any;
	condition: string;
	execErrState: string;
	folderUID: string;
	for: string;
	id?: number;
	isPaused?: boolean;
	labels?: any;
	noDataState: string;
	orgID: number;
	provenance?: string;
	ruleGroup: string;
	title: string;
	uid?: string;
	updated?: string;
	query: string;
}

enum SeverityLvl {
	Minimal,
	Moderate,
	Critical,
	Urgent
}

async function fetchAlerts(): Promise<ProvisionedAlertRule[]> {
	const response = getBackendSrv().fetch<ProvisionedAlertRule[]>({
		url: alertsUrl,
	});

	return lastValueFrom(response).then((response) => response.data);
}

export { ProvisionedAlertRule, SeverityLvl, alertsUrl, fetchAlerts };
