import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HomePage } from '../../pages/Home';
import { PageWithTabs } from '../../pages/WithTabs';
import { WithDrilldown } from '../../pages/WithDrilldown';
import { prefixRoute } from '../../utils/utils.routing';
import { ROUTES } from '../../constants';
import { JsonToDashPage } from '../../pages/JsonToDash';
import { HomeAlertas } from 'pages/Alertas';
import { CriarAlerta } from 'pages/Alertas/CriarAlerta/CriarAlerta';
import { ListarAlertas } from 'pages/Alertas/ListarAlertas/ListarAlertas';
import { PontoContacto } from 'pages/Alertas/PontoContacto/PontoContacto';
import { HomeSensores } from 'pages/Sensores/HomeSensores';
import { Maquinas } from 'pages/Sensores/Maquinas/Maquinas';
import { Sensor } from 'pages/Sensores/Sensor/Sensor';
import { CriarVisualizacao } from 'pages/Sensores/CriarVisualização';

export const Routes = () => {
	return (
		<Switch>
			<Route path={prefixRoute(`${ROUTES.WithTabs}`)} component={PageWithTabs} />
			<Route path={prefixRoute(`${ROUTES.WithDrilldown}`)} component={WithDrilldown} />
			<Route path={prefixRoute(`${ROUTES.Home}`)} component={HomePage} />
			<Route path={prefixRoute(`${ROUTES.HomeAlertas}`)} component={HomeAlertas} />
			<Route path={prefixRoute(`${ROUTES.CriarAlerta}`)} component={CriarAlerta} />
			<Route path={prefixRoute(`${ROUTES.ListarAlertas}`)} component={ListarAlertas} />
			<Route path={prefixRoute(`${ROUTES.PontoContacto}`)} component={PontoContacto} />
			<Route path={prefixRoute(`${ROUTES.HomeSensores}`)} component={HomeSensores} />
			<Route path={prefixRoute(`${ROUTES.Sensor}`)} component={Sensor} />
			<Route path={prefixRoute(`${ROUTES.CriaVisualizacao}`)} component={CriarVisualizacao} />
			<Route path={prefixRoute(`${ROUTES.Maquinas}`)} component={Maquinas} />
			<Route path={prefixRoute(`${ROUTES.RenderFromJSON}`)} component={JsonToDashPage} />
			<Redirect to={prefixRoute(ROUTES.Home)} />
		</Switch>
	);
};
