import { EmbeddedScene, SceneApp, SceneAppPage, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { ROUTES } from '../../../constants';
import { prefixRoute } from '../../../utils/utils.routing';
import React, { useMemo } from 'react';
import { CriarVisualizacaoPage } from './CustomSceneObject';

export function myScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new CriarVisualizacaoPage({ isActive: true }),
        }),
      ],
    }),
  });
}

const getScene = () =>
  new SceneApp({
    pages: [
      new SceneAppPage({
        title: 'Pagina de criação de Visualizações',
        url: prefixRoute(`${ROUTES.CriaVisualizacao}`),
        hideFromBreadcrumbs: false,
        getScene: () => {
          return myScene();
        },
      }),
    ],
  });

export const CriarVisualizacao = () => {
  const scene = useMemo(() => getScene(), []);

  return (
    <>
      <scene.Component model={scene} />
    </>
  );
};
