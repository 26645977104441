import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import '../estilos.css';
import React from 'react';

interface PageState extends SceneObjectState {
  isActive: boolean;
}

export class MaquinasPage extends SceneObjectBase<PageState> {
  public static Component = MaquinasPageRenderer;
}

function MaquinasPageRenderer({ model }: SceneComponentProps<MaquinasPage>) {
  const { isActive } = model.useState();

  return <div> {isActive} </div>;
}
