import { Field } from "@grafana/data";
import { Stack, Input, RadioButtonGroup, Button } from "@grafana/ui";
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import React from "react";

function QuickPickField({
	name,
	options,
}: {
	name: string;
	options: string[];
}) {
	const { control, register, setValue, getValues } = useFormContext();

	return (
		<Controller
			name={`${name}QuickPick`}
			control={control}
			defaultValue=""
			render={({ field }) => (
				<Stack>
					<Input
						{...register(name, { required: true })}
						width={10}
						onInput={(data) => {
							const value = (data.target as HTMLInputElement).value;
							setValue(`${name}QuickPick`, options.includes(value) ? value : "");
						}
						}
					/>

					{/* <RadioButtonGroup */}
					{/* 	options={options.map((interval) => ({ label: interval, value: interval }))} */}
					{/* 	value={field.value} */}
					{/* 	onChange={(value) => { */}
					{/* 		setValue(name, value); */}
					{/* 		field.onChange(value); */}
					{/* 	}} */}
					{/* /> */}

					{/* Buttons implementation (instead of RadioButtonGroup) */}
					<Stack direction="row" gap={0.5} role="listbox">
						{options.map((interval) => (
							<Button
								role="option"
								key={interval}
								variant={(interval === getValues(name)) ? "primary" : "secondary"}
								size="sm"
								onClick={() => {
									setValue(name, interval);
									field.onChange(interval);
								}}
							>
								{interval}
							</Button>
						))}
					</Stack>
				</ Stack>
			)}
		/>
	);
}

export { QuickPickField };
