import React from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';

import { SelectableValue } from '@grafana/data';
import { SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { AsyncSelect, Box, Button, Card, Field, Input, RadioButtonGroup, Select, Stack, Text, TextArea } from '@grafana/ui';

import { QuickPickField } from '../../../components/UIUtils'

import { SeverityLvl } from '../Alert';
import { ContactPoint, fetchContactPoints } from '../ContactPoints';
import { Device, fetchDevice, fetchDevices } from '../Device';

import '../estilos.css';
import { forEach } from 'lodash';

interface PageState extends SceneObjectState {
	isActive: boolean;
}

export class CriarAlertaPage extends SceneObjectBase<PageState> {
	public static Component = CriarAlertaPageRenderer;
}

const loadAsyncContactPointOptions = async (): Promise<Array<SelectableValue<String>>> => {
	const response = await fetchContactPoints()

	return response.map((contactPoint: ContactPoint): SelectableValue<String> => (
		{
			"label": contactPoint.name,
			"value": contactPoint.uid
		}
	))
}

const loadAsyncDeviceOptions = async (): Promise<Array<SelectableValue<String>>> => {
	const response = await fetchDevices()

	return response.map((device: Device): SelectableValue<String> => (
		{
			"label": device.device_Name,
			"value": String(device.id)
		}))
}

const createAlert = async (data: any) => {
	console.log(data)
}

function DataAlertForm() {
	const { control, register, setValue } = useFormContext();

	const pendingPeriodPresets = React.useMemo(() => ["24h", "1h", "30m", "10m", "5m", "1m", "10s"], []);

	return (
		// UGLEEE
		// <Box borderStyle="solid" borderColor="strong" padding={2} marginY={2}>
		<>
			<Text >Configuração de alerta de dados</Text>
			<Stack>
				<Field label="Tipo de agregação">
					<Controller
						name="agregationType"
						control={control}
						defaultValue=""
						rules={{ required: true }}
						render={({ field }) => (
							<RadioButtonGroup
								options={[
									{ label: "Máximo", value: "max" },
									{ label: "Mínimo", value: "min" },
									{ label: "Média", value: "avg" }
								]}
								{...field}
							/>
						)}
					/>
				</Field>

				<Field label="Operador">
					<Controller
						name="operator"
						control={control}
						defaultValue=""
						rules={{ required: true }}
						render={({ field }) => (
							<RadioButtonGroup
								options={[
									{ label: "Superior", value: "greater" },
									{ label: "Inferior", value: "lesser" },
									{ label: "Igual", value: "equal" }
								]}
								{...field}
							/>
						)}
					/>
				</Field>

				<Field label="Threshold">
					<Input
						type="number"
						width={20}
						{...register("threshold", { required: true })}
					/>
				</Field>
			</Stack>

			<Field label="Período de verificação">
				<QuickPickField name="pendingPeriod" options={pendingPeriodPresets} />
			</Field>
		</>
		// </Box>
	)
}

function MessageAlertForm() {
	const { register } = useFormContext();

	return (
		<Box borderStyle="solid" borderColor="strong" padding={2} marginY={2}>
			<Text >Configuração de alerta de mensagem</Text>
			<Field label="Threshold">
				<Input type="number" {...register("threshold", { required: true })} />
			</Field>
			<Field label="Número de ocurrências">
				<Input type="number" {...register("occurences", { required: true })} />
			</Field>
		</Box>
	)
}

function EvaluationForm() {
	const evaluationFrequencyPresets = React.useMemo(() => ["24h", "1h", "30m", "10m", "5m", "1m", "10s"], []);

	return (
		<Card>
			<Card.Heading>Avaliação</Card.Heading>
			<Card.Description>
				<Field label="Intervalo de avaliação" description="Frequência com que é feita a avaliação dos dados.">
					<QuickPickField name="evaluationFrequency" options={evaluationFrequencyPresets} />
				</Field>
			</Card.Description>
		</Card>
	);
}

function CriarAlertaPageRenderer() {
	const [device, setDevice] = React.useState<Device>();

	const methods = useForm();

	const alertType = methods.watch("alertType");

	methods.watch(async (data, { name, type }) => {
		if (type != "change") {
			return
		}

		if (name == "device") {
			const fetchedDevice = await fetchDevice(data.device.value)

			if (fetchedDevice === undefined) {
				return
			}

			methods.resetField("field")
			setDevice(fetchedDevice)
		}
	})

	const DeviceFieldSelect = () => {
		let options: Array<SelectableValue<String>> = [];

		if (device) {
			forEach(device.parameters, (parameter) => {
				forEach(parameter.dataToRead, (field) => {
					options.push({
						"label": parameter.param_Name + " - " + field.dataToRead_FldName,
						"value": String(options.length)
					})
				})
			})
		}

		return <Field label="Campo">
			<Controller name="field"
				control={methods.control}
				rules={{ required: true }}
				render={({ field }) => (
					<Select
						options={options}
						defaultOptions
						{...field}
					/>
				)}
			/>
		</Field>
	}

	return (
		<div >
			<form
				onSubmit={methods.handleSubmit(createAlert)}
			>
				<Field label="Título" description="Pequeno texto com regras simples">
					<Input {...methods.register("title", { required: true, maxLength: 16 })} />
				</Field>

				<Field label="Descrição" description="Texto breve detalhes sobre o alerta">
					<TextArea {...methods.register("description", { required: true, maxLength: 500 })} />
				</Field>

				<Field label="Sensor">
					<Controller name="device" control={methods.control} rules={{ required: true }}
						render={({ field }) => (
							<AsyncSelect
								loadOptions={loadAsyncDeviceOptions}
								defaultOptions
								{...field}
							/>
						)}
					/>
				</Field>

				<DeviceFieldSelect />

				<Field label="Severidade" description="Nível de severidade">
					<Controller name="severityLvl" control={methods.control} rules={{ required: true }}
						render={({ field }) => (
							<Select
								options={[
									{ label: "Mínimo", value: SeverityLvl.Minimal },
									{ label: "Moderado", value: SeverityLvl.Moderate },
									{ label: "Crítico", value: SeverityLvl.Critical },
									{ label: "Urgente", value: SeverityLvl.Urgent }
								]}

								{...field}
							/>
						)}
					/>
				</Field>

				<Field label="Ponto de Contacto">
					<Controller
						name="contactPoint"
						control={methods.control}
						defaultValue=""
						rules={{ required: true }}
						render={({ field }) => (
							<AsyncSelect
								loadOptions={loadAsyncContactPointOptions}
								defaultOptions
								{...field}
							/>
						)}
					/>
				</Field>

				<Card isCompact={false}>
					<Card.Heading>Regra</Card.Heading>
					<Card.Description>
						<Field label="Tipo de Alerta">
							<Controller
								name="alertType"
								control={methods.control}
								defaultValue="data"
								rules={{ required: true }}
								render={
									({ field }) => (
										<RadioButtonGroup
											options={[
												{ label: "Dados de sensor", value: "data" },
												{ label: "Validação de mensagem", value: "message" }
											]}

											{...field}
										/>
									)
								}
							/>
						</Field>

						<FormProvider {...methods}>
							{(alertType === undefined || alertType === "data") && <DataAlertForm />}
							{alertType === "message" && <MessageAlertForm />}
						</FormProvider>
					</Card.Description>
				</Card>

				<FormProvider {...methods}>
					<EvaluationForm />
				</FormProvider>

				<Button type="submit" style={{
					position: "fixed",
					bottom: "20px",
					right: "20px",
					zIndex: 1000
				}} >Criar Alerta</Button>
			</form>
		</div >
	);
}
