import { EmbeddedScene, SceneApp, SceneAppPage, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { ROUTES } from '../../../constants';
import { prefixRoute } from '../../../utils/utils.routing';
import React, { useMemo } from 'react';
import { CriarAlertaPage } from './CustomSceneObject';
import '../estilos.css';

export function sceneCriarAlerta() {
	return new EmbeddedScene({
		body: new SceneFlexLayout({
			children: [
				new SceneFlexItem({
					body: new CriarAlertaPage({ isActive: true }),
				}),
			],
		}),
	});
}

const getScene = () =>
	new SceneApp({
		pages: [
			new SceneAppPage({
				title: 'Criar Alerta',
				url: prefixRoute(`${ROUTES.CriarAlerta}`),
				hideFromBreadcrumbs: false,
				getScene: () => {
					return sceneCriarAlerta();
				},
			}),
		],
	});

export const CriarAlerta = () => {
	const scene = useMemo(() => getScene(), []);

	return (
		<>
			<scene.Component model={scene} />
		</>
	);
};
