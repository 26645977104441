import React, { useMemo } from 'react';
import DashboardRenderer from './DashboardRenderer';
import { RootDashboardJsonType } from 'utils/DashboardTypes';

const apiKey = 'glsa_tOOeVwKmf0qhumXMBEjhuu3oIGyJc2Zm_22e679f6';

export const JsonToDashPage: React.FC = ({ uid = 'e22f0026-fcb2-4d30-8527-80ffd05866c6' }: { uid?: string }) => {
	const [dashboardJson, setDashboardJson] = React.useState<RootDashboardJsonType>();
	const fetchDashboard = async () => {
		const response = await fetch(`${window.location.origin}/api/dashboards/uid/${uid}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${apiKey}`,
			},
		});
		const data = await response.json();
		console.log(data);
		setDashboardJson(data);
	};

	React.useEffect(() => {
		if (uid) {
			fetchDashboard();
		}
	}, [uid]);
	const scene = useMemo(() => DashboardRenderer(dashboardJson), [dashboardJson]);

	return (
		<div style={{ padding: '20px' }}>
			<h1>Pagina de Teste para JSON</h1>
			<scene.Component model={scene} />
		</div>
	);
};
