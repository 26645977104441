const apiKey = 'glsa_tOOeVwKmf0qhumXMBEjhuu3oIGyJc2Zm_22e679f6';

export const fetchDashboardJson = async () => {
  try {
    const response = await fetch(`${window.location.origin}/api/dashboards/uid/ddz1viffkk3r4a`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Erro ao buscar o dashboard: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao buscar o dashboard:', error);
    throw error;
  }
};
