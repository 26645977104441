import { ConfirmModal, LoadingPlaceholder, Button, Card, Icon } from '@grafana/ui';
import { SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import React from 'react';
import '../estilos.css';
import { ContactPoint, fetchContactPoints, deleteContactPoint } from '../ContactPoints';

interface PageState extends SceneObjectState {
	isActive: boolean;
}

export class PontoContactoPage extends SceneObjectBase<PageState> {
	public static Component = PontoContactoPageRenderer;
}


function PontoContactoPageRenderer() {
	const [isLoading, setLoading] = React.useState(true);
	const [contactPoints, setContactPoints] = React.useState<ContactPoint[]>();
	const [isModalOpen, setModalOpen] = React.useState(false);
	const [selectedContactPoint, setSelectedContactPoint] = React.useState("");

	React.useEffect(() => {
		getContactPoints();
	}, [])

	const getContactPoints = () => {
		fetchContactPoints().then((value) => {
			setLoading(false)
			setContactPoints(value)
		})
	}

	return (
		<>
			<div className="div-full-w">
				{isLoading ? <LoadingPlaceholder text="A carregar..." /> :
					contactPoints && contactPoints.map((contactPoint: ContactPoint) => (
						<>
							<Card key={contactPoint.uid}>
								<Card.Heading>
									{contactPoint.name} {contactPoint.type === "email" && < Icon name="envelope" />}
								</Card.Heading>
								<Card.Description>
									{contactPoint.settings.addresses}
								</Card.Description>
								<Card.Meta>
									{contactPoint.type}
								</Card.Meta>
								<Card.Actions>
									<Button variant="secondary">
										Editar
									</Button>
									<Button variant="secondary" onClick={() => {
										setSelectedContactPoint(contactPoint.uid)
										setModalOpen(true)
									}}>
										Apagar
									</Button>

								</Card.Actions>
							</Card>

							<ConfirmModal
								isOpen={isModalOpen}
								title="Apagar ponto de contacto"
								body="Tem a certeza que quer apagar este ponto de contacto?"
								confirmText="Confirmar"
								icon="exclamation-triangle"
								onConfirm={async () => {
									deleteContactPoint(selectedContactPoint);
									setModalOpen(false);
									getContactPoints();
								}}
								onDismiss={() =>
									setModalOpen(false)
								}
							/>
						</>

					))
				}

				<Button variant="secondary"><Icon name="plus" /> Adicionar</Button>

			</div>

		</>
	);
}
