import { getBackendSrv, FetchResponse } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';

const contactPointsUrl = "/api/v1/provisioning/contact-points";

interface ContactPoint {
	uid: string;
	name: string;
	type: string;
	settings: {
		addresses: string;
	};
	disableResolveMessage: boolean;
}

async function fetchContactPoints(): Promise<ContactPoint[]> {
	const response = getBackendSrv().fetch<ContactPoint[]>({
		url: contactPointsUrl,
	});

	return lastValueFrom(response).then((response: FetchResponse): ContactPoint[] => response.data);
}

async function deleteContactPoint(uid: string): Promise<boolean> {
	try {
		const response = await fetch(contactPointsUrl + "/" + uid, {
			method: "DELETE",
			credentials: "include",
		});

		if (!response.ok) {
			console.error(response)
			return false;
		}

		return true;

	} catch (err) {
		if (err instanceof Error) {
			console.error(err.message)
		}

		return false;
	}
}

export { fetchContactPoints, ContactPoint, contactPointsUrl, deleteContactPoint };
