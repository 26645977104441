import { LoadingPlaceholder, Button, Card } from '@grafana/ui';
import { SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import React from 'react';
import '../estilos.css';
import { ProvisionedAlertRule, fetchAlerts } from '../Alert';

interface PageState extends SceneObjectState {
	isActive: boolean;
}

export class ListarAlertasPage extends SceneObjectBase<PageState> {
	public static Component = ListarAlertasPageRenderer;
}

function ListarAlertasPageRenderer() {
	const [isLoading, setLoading] = React.useState(true);
	const [rules, setRules] = React.useState<ProvisionedAlertRule[]>();

	React.useEffect(() => {
		getAlerts();
	}, [])

	const getAlerts = () => {
		fetchAlerts().then((value) => {
			setLoading(false)
			setRules(value)
		})
	}

	return (
		<div className="div-full-w">
			<ul
				style={{
					display: 'grid',
					listStyle: 'none',
					padding: '20px'
				}}
			>
				{isLoading ? <LoadingPlaceholder text="A carregar..." /> :
					rules && rules.map((rule: ProvisionedAlertRule) => (
						<li>
							<Card key={rule.id}>
								<Card.Heading>
									{rule.title}
								</Card.Heading>
								<Card.Description>
									{rule.query}
								</Card.Description>
								<Card.Meta>
									{rule.updated}
									{rule.isPaused}
								</Card.Meta>
								<Card.Actions>
									<Button variant="secondary">
										Editar
									</Button>
									<Button variant="secondary">
										Apagar
									</Button>
								</Card.Actions>
							</Card>
						</li>
					))
				}
			</ul>
		</div>
	);
}
