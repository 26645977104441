import { EmbeddedScene, SceneApp, SceneAppPage, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { ROUTES } from '../../../constants';
import { prefixRoute } from '../../../utils/utils.routing';
import React, { useMemo } from 'react';
import '../estilos.css';
import { PontoContactoPage } from './CustomSceneObject';

export function scenePontoContato() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new PontoContactoPage({ isActive: true }),
        }),
      ],
    }),
  });
}

const getScene = () =>
  new SceneApp({
    pages: [
      new SceneAppPage({
        title: 'Pagina de pontos de contacto',
        url: prefixRoute(`${ROUTES.PontoContacto}`),
        hideFromBreadcrumbs: false,
        getScene: () => {
          return scenePontoContato();
        },
      }),
    ],
  });

export const PontoContacto = () => {
  const scene = useMemo(() => getScene(), []);

  return (
    <>
      <scene.Component model={scene} />
    </>
  );
};
